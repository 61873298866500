<template>
  <b-container fluid class="login" :style="checkStyles">
    <b-container class="">
      <b-row align-h="center" align-v="end" class="mb-3">
        <b-col md="6" lg="5" xl="4" class="login-header">
          <img v-if="authJSON.attributes" :style="logoStyles" :src="logoSrc" alt="">
          <div class="login-title">
            <p v-if="pageType === 'login'">{{$t('GENERAL_LOGIN_TITLE')}}</p>
            <p v-else-if="pageType === 'register'">{{$t('GENERAL_REGISTER_TITLE')}}</p>
            <p v-else-if="pageType === 'resetpw'">{{$t('GENERAL_RESET_PASSWORD_TITLE')}}</p>
            <p v-else-if="pageType === 'newpw'">{{$t('GENERAL_NEW_PASSWORD_TITLE')}}</p>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="6" lg="5" xl="4" class="login-wrapper">
          <form @submit.prevent="validateBeforeSubmit" v-if="pageType === 'login'">
            <b-form-group id="email">
              <b-form-input
                  name="Username"
                  id="login-username"
                  v-model="username"
                  v-validate="{required: true, regex: /^[@\\.\w]*$/ }"
                  :class="{'is-danger': errors.has('Username') }"
                  :state="errors.has('Username') ? false : null"
                  type="text"
                  :placeholder="$t('GENERAL_USERNAME')"
              ></b-form-input>
              <span v-show="errors.has('Username')" class="text-danger">{{ errors.first('Username') }}</span>
            </b-form-group>
            <b-form-group id="password">
              <span class="password-wrapper">
                <b-form-input
                    name="Password"
                    ref="login-password"
                    id="login-password"
                    v-model="password"
                    v-validate="'required'"
                    :type="passwordType"
                    :placeholder="$t('GENERAL_PASSWORD')"
                    :state="errors.has('Password') ? false : null"
                    :class="{ 'is-danger': errors.has('Password') }"
                ></b-form-input>
              <div
                  v-if="password"
                  class="show-password-icon"
                  @mousedown="showPasswordPressed"
                  @touchstart="showPasswordPressed"
                  @mouseleave="showPasswordReleased"
                  @mouseup="showPasswordReleased"
                  @touchend="showPasswordReleased"
                  @touchcancel="showPasswordReleased"
              >
                <font-awesome-icon v-if="passwordType=='password'" :icon="['fas', 'eye']"></font-awesome-icon>
                <font-awesome-icon v-else :icon="['fas', 'eye-slash']"></font-awesome-icon>
              </div>
              </span>
              <span
                  v-show="errors.has('Password')"
                  class="text-danger"
              >{{ errors.first('Password') }}</span>
            </b-form-group>
            <b-row align-v="center" class="mb-3">
              <b-col>
                <button class="button full-width secondary" type="submit">{{$t('GENERAL_LOGIN')}}</button>
              </b-col>
            </b-row>
            <div class="register-link">
              <b-row align-v="center">
                <b-col>
                  <router-link to="/register" tag="button" class="button full-width btn-cancel register-button">
                    {{$t('GENERAL_REGISTER')}}
                  </router-link>
                </b-col>
              </b-row>
            </div>

            <div>
            </div>
          </form>
          <template v-else>
            <template v-for="item in authJSON.children">
              <component :is="item.type" :obj="item" :key="item.key"></component>
            </template>
            <b-row align-v="center" class="mb-3">
              <b-col class="text-center">
                <router-link to="/login" class="">{{$t('GENERAL_LOGIN_TITLE')}}</router-link>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-2 mb-1">
        <b-col cols="auto">
          <template v-if="pageType === 'login'">
            <div>
              <router-link to="/reset-password" class="">{{$t('GENERAL_RESET_PASSWORD')}}</router-link>
            </div>
          </template>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="auto">
          <div class="error" v-if="responseError">{{$t(responseError)}}</div>
        </b-col>
      </b-row>
    </b-container>
    <div class="versionNr">
      &copy; Lighthouse v {{ backendVersion }} {{ version }}
    </div>
    <div class="policies" v-if="hasPolicies">
      <ul>
        <li v-for="policy in authJSON.attributes.policies">
          <a :href="policy.url" target="_blank">{{ policy.name }}</a>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
  import axios from 'axios'
  import mixinFunctions from '@/mixin/mixinFunctions'
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { findTarget } from '../js/find-target.js'
  import { eventBus } from '../services/event-bus'


  export default {
    name: 'login',
    mixins: [mixinFunctions],
    props: {},
    data () {
      return {
        logo: '',
        obj: {},
        email: '',
        name: '',
        phone: '',
        url: '',
        username: '',
        password: '',
        responseError: '',
        pageType: 'login',
        passwordType: 'password'
      }
    },
    computed: {
      ...mapState({
        version: 'version',
        backendVersion: 'backendVersion',
        authJSON: 'authJSON'
      }),
      logoStyles(){
        return this.authJSON?.attributes?.logo?.styles
      },
      logoSrc(){
        return this.authJSON?.attributes?.logo?.src
      },
      hasPolicies(){
        return this.authJSON?.attributes?.policies
      }
    },
    methods: {
      ...mapMutations({
        setLoading: 'setLoading',
        stopLoading: 'stopLoading',
        setBackendVersion: 'setBackendVersion'
      }),
      login: function () {
        const { username, password } = this
        this.responseError = ''
        this.$store
          .dispatch('login', { username, password })
          .then(result => {
            if (result.error) {
              this.responseError = result.error
              this.password = ''
              if (this.$refs['login-password']) this.$refs['login-password'].$el.focus();
            } else if (result.redirect) {
              //from page
              console.log('redirecting to:' + result.redirect)
              this.$router.push({ path: result.redirect })
            }
          })
          .catch(error => {
            console.log('error: ', error)
          })
      }
      ,
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.login()
          }
        })
      },
      getForm (link) {
        this.setLoading()
        let api = this.$clientConfig.VUE_APP_API_URL + link
        return axios
          .get(api)
          .then(response => {
            let data = response.data
            console.log('fetchJSON success response', data)
            if (data && data.redirect) {
              this.$router.push({ path: data.redirect })
            } else if (data && data.page) {
              this.$store.commit('setAuthJSON', response.data.page)
              let version = response.data.page.attributes.version
              if (version) this.setBackendVersion(version)
              if (this.authJSON && this.authJSON.attributes.projectName) {
                document.title = this.$t(this.authJSON.attributes.projectName)
              }
            }
          })
          .catch(error => {
            console.log(error)
            return Promise.reject(error)
          })
          .finally(() => {
            this.stopLoading()
          })
      },
      showPasswordPressed (e) {
        //prevent contextmenu opening in mobile
        e.preventDefault && e.preventDefault()
        this.passwordType = 'text'
      },
      showPasswordReleased (e) {
        e.preventDefault && e.preventDefault()
        this.passwordType = 'password'
      },
      showError (errorMsg) {
        // show error message in text and hide all toast
        if (errorMsg && errorMsg.error) {
          this.responseError = errorMsg.error.message
          setTimeout(() => {
            this.$root.$emit('bv::hide::toast')
          }, 20)
        }
      }
    },
    watch: {
      '$route' (to, from) {
        console.log('to: ', to)
        this.responseError = ''
        if (to.path === '/register') {
          this.pageType = 'register'
          this.getForm(to.path)
        } else if (to.path === '/reset-password') {
          this.pageType = 'resetpw'
          this.getForm(to.path).then(() => {
            // hier input value invullen
            let target = findTarget(this.authJSON, 'password-reset-input')
            let element = this.authJSON
            for (let index = 0; index < target.path.length - 1; index++) {
              const path = target.path[index]
              element = element.children[path]
            }
            this.$set(element.children[target.path[target.path.length - 1]].attributes, 'value', this.username)
          })
        } else if (to.path === '/login') {
          this.pageType = 'login'
        } else if (to.name === 'new-password') {
          this.pageType = 'newpw'
          this.getForm(to.path)
          console.log(to.path)
        }
      },
      authJSON () {
        if (this.authJSON && this.authJSON.attributes.projectName) {
          document.title = this.$t(this.authJSON.attributes.projectName)
        }
      }
    },
    created () {
      let to = this.$route
      this.getForm(to.path)
      if (to.path === '/register') {
        this.pageType = 'register'
      } else if (to.path === '/reset-password') {
        this.pageType = 'resetpw'
      } else if (to.name === 'new-password') {
        this.pageType = 'newpw'
      }
      eventBus.$on('error-message', this.showError)
    },
    beforeDestroy () {
      eventBus.$off('error-message', this.showError)
    }
  }
</script>

<style lang="scss">
  .login {
    background: $background-url;
    background-size: cover;
    background-position: right;
    flex-grow: 1;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    position: relative;

    .lh-p {
      border: 1px solid $input-focus-border-color;
      border-radius: $border-radius;
      text-align: center;
      padding: 1em 1em 1.2em 1em;
      margin-bottom: 1rem;

      p {
        margin-bottom: 0;
      }
    }

    &-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      img {
        max-width: 47%;
        max-height: $login-logo-height;
      }
    }

    &-title {
      display: flex;
      justify-content: flex-end;

      p {
        margin-bottom: 0;
        font-weight: bold;
        color: $secondary-color;
      }
    }

    .col-xl-4 {
      @include media-breakpoint-up(xl) {
        max-width: 450px;
      }
    }

    .login form {
      text-align: left;
      width: 60%;
      margin: 0 auto;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    label {
      display: inline-block;
      margin-right: 1em;
      text-align: right;
    }

    .password-wrapper {
      display: flex;
      position: relative;

      input {
        background-image: none;
      }

      .show-password-icon {
        position: absolute;
        right: 10px;
        top: 6px;
        font-size: 16px;
        color: $medium-grey-alt;
        cursor: pointer;

        @media (max-width: 767px) {
          top: 3px;
          font-size: 20px;
        }
      }
    }
  }

  a {
    color: $medium-grey;
    text-decoration: underline;

    &:hover, &:focus {
      color: $medium-dark-grey;
    }
  }

  .loading-wrapper {
    z-index: 2000;
  }

  .spinner-small {
    position: absolute;
    top: calc(50 * var(--vh-unit));
    left: calc(50 * var(--vw-unit));
    font-size: 2rem;
    color: $primary-color;
  }

  .error {
    color: $red;
  }

  .register-link {
    display: $register-link-visibility;
  }

  .register-button {
    margin-bottom: 1rem;
  }

  .policies,
  .versionNr {
    position: absolute;
    bottom: 18px;
    left: 0;
    color: $medium-grey;
    font-size: 12px;
    margin-left: 15px;
    margin-top: 10px;
  }

  .policies {
    left: auto;
    right: 0;
    margin-right: 15px;
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      li {
        margin-right: 1em;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
